<template>
    <v-card variant="text" class="mt-4">
      <v-form v-model="form" @submit.prevent="next">
        <v-card-text>
          <p class="text-center text-h5">{{ $t('app.Labels.MedInformation').toUpperCase() }}</p>
          <p class="my-4 text-medium-emphasis">{{ $t('app.Labels.MedicalInformation') }}</p>
          <template v-for="(field, index) in fields" :key="index">
            <v-row>
              <v-col class="my-auto" cols="9" md="9">
                <div class="font-weight-medium">{{ $t(field.label) }}</div>
                <div class="text-grey text-caption">{{ $t(field.subLabel) }}</div>
              </v-col>
              <v-col class="my-auto" cols="3" md="3">
                <v-radio-group v-model="model[field.id]" :rules="[v => v !== undefined || $t('app.Errors.RequiredSelection')]" inline>
                  <v-radio :label="$t('app.Labels.Yes')" :value="true"></v-radio>
                  <v-radio :label="$t('app.Labels.No')" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="field.id === '14' && model['14']">
              <v-col class="my-auto" cols="12">
                <v-text-field v-model="alergias" :rules="[
                    v => !!v.length || $t('app.Errors.AtttachDocumentValidation'),
                    v => !v.includes('-') || $t('app.Errors.InvalidCharacter')
                ]" :label="$t('app.Fields.AllergyDetails')" type="text" pattern=".*"></v-text-field>
              </v-col>
            </v-row>
          </template>
          <v-row v-if="model['15']">
            <v-col class="my-auto" cols="12">
              <v-text-field class="my-4" v-model="otros_pregunta_formato"
                :rules="[v => !!v || $t('app.Errors.AnotherMedicalConditionValidation')]"
                :label="$t('app.Fields.AnotherMedicalCondition')"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="orange-darken-1" variant="flat" @click="atras()" type="submit"
            :text="$t('app.Buttons.Back')"></v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" variant="flat" type="submit" :text="$t('app.Buttons.Next')"></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </template>
  
  <script>
  export default {
    name: 'App',
    data: () => ({
      form: false,
      fields: [
        { id: '1', label: 'app.Fields.RecentSurgery', subLabel: 'app.Fields.RecentSurgerySub' },
        { id: '2', label: 'app.Fields.Disorders', subLabel: 'app.Fields.DisordersSub' },
        { id: '3', label: 'app.Fields.Diabetes', subLabel: 'app.Fields.DiabetesSub' },
        { id: '4', label: 'app.Fields.Hypertension', subLabel: 'app.Fields.HypertensionSub' },
        { id: '5', label: 'app.Fields.Pregnancy', subLabel: 'app.Fields.PregnancySub' },
        { id: '6', label: 'app.Fields.NeurologicalDisorders', subLabel: 'app.Fields.NeurologicalDisordersSub' },
        { id: '7', label: 'app.Fields.RecentInfections', subLabel: 'app.Fields.RecentInfectionsSub' },
        { id: '9', label: 'app.Fields.CardiacProblems', subLabel: 'app.Fields.CardiacProblemsSub' },
        { id: '10', label: 'app.Fields.RespiratoryDisorders', subLabel: 'app.Fields.RespiratoryDisordersSub' },
        { id: '11', label: 'app.Fields.OphthalmologicalProblems', subLabel: 'app.Fields.OphthalmologicalProblemsSub' },
        { id: '12', label: 'app.Fields.DigestiveProblems', subLabel: 'app.Fields.DigestiveProblemsSub' },
        { id: '13', label: 'app.Fields.SleepApnea', subLabel: 'app.Fields.SleepApneaSub' },
        { id: '14', label: 'app.Fields.Allergies', subLabel: 'app.Fields.AllergiesSub' },
        { id: '15', label: 'app.Fields.AnotherMedicalCondition', subLabel: 'app.Fields.AnotherMedicalConditionSub' }
      ]
    }),
    computed: {
      model: {
        get() {
          return this.$store.state.datos_medicos || {};
        },
        set(newValue) {
          this.$store.commit('SET_DATOS_MEDICOS', newValue);
        }
      },
      otros_pregunta_formato: {
        get() {
          return this.$store.state.otros_pregunta_formato || '';
        },
        set(newValue) {
          this.$store.commit('SET_OTROS_PREGUNTA_FORMATO', newValue);
        }
      },
      alergias: {
        get() {
          return this.$store.state.alergias;
        },
        set(newValue) {
          this.$store.commit('SET_ALERGIAS', newValue);
        }
      }
    },
    methods: {
      next() {
        if (!this.form) return;
        this.$store.commit('SET_VENTANA', 5);
      },
      atras() {
        this.$store.commit('SET_VENTANA', 3);
      }
    }
  }
  </script>
  