import { createStore } from "vuex";
import axios from "axios";
import i18n from "@/i18n";
import dialcode from "./modules/dialcode";

export default createStore({
  state: {
    ventana: 1,
    empresas: [],
    visitante: {
      fecha_nacimiento: "",
      tipo_documento_visitante_id: null,
      documento_identidad: "",
    },
    selectedUnits: null,
    declareNoCoca: false,
    declareNoMarijuana: false,
    signos: {
      peso: "",
      talla: "",
    },
    datos_medicos: [],
    otros_pregunta_formato: null,
    alergias: "",
    centro_salud: [],
    tipo_seguro: [],
    tipo_visitante: [],
    adjunto_declaracion_jurada: null,
  },
  getters: {},
  mutations: {
    SET_EMPRESAS(state, empresas) {
      state.empresas = empresas;
    },
    SET_VENTANA(state, ventana) {
      state.ventana = ventana;
    },
    SET_VISITANTE(state, visitante) {
      state.visitante = visitante;
    },
    SET_UNITS(state, units) {
      state.selectedUnits = units;
    },
    SET_DECLARE_NO_COCA(state, declareNoCoca) {
      state.declareNoCoca = declareNoCoca;
    },
    SET_DECLARE_NO_MARIJUANA(state, declareNoMarijuana) {
      state.declareNoMarijuana = declareNoMarijuana;
    },
    SET_DATOS_MEDICOS(state, data) {
      state.datos_medicos = data;
    },
    SET_OTROS_PREGUNTA_FORMATO(state, data) {
      state.otros_pregunta_formato = data;
    },
    SET_ALERGIAS(state, alergias) {
      state.alergias = alergias;
    },
    SET_CENTRO_SALUD(state, centro_salud) {
      state.centro_salud = centro_salud;
    },
    SET_TIPO_SEGURO(state, tipo_seguro) {
      state.tipo_seguro = tipo_seguro;
    },
    SET_TIPO_VISITANTE(state, tipo_visitante) {
      state.tipo_visitante = tipo_visitante;
    },
    SET_ADJUNTO_DECLARACION_JURADA(state, adjunto_declaracion_jurada) {
      state.adjunto_declaracion_jurada = adjunto_declaracion_jurada;
    },
  },
  actions: {
    async fetchCentroSalud({ commit }) {
      try {
        const { data } = await axios.get(`/api/centrosalud`);
        commit("SET_CENTRO_SALUD", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchTipoSeguro({ commit }) {
      try {
        const { data } = await axios.get(`/api/tiposeguro`);
        commit("SET_TIPO_SEGURO", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchTipoVisitante({ commit }) {
      try {
        const { data } = await axios.get(`/api/tipovisitante`);
        commit("SET_TIPO_VISITANTE", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    guardarDatosPersonales({ commit }, form) {
      commit("SET_VISITANTE", form);
      commit("SET_VENTANA", 3);
    },
    async guardarFormulario({ state, commit }) {
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };

      const getCurrentLocale = () => {
        return i18n.global.locale.value;
      };

      const data = {
        visitante: state.visitante,
        datos_medicos: state.datos_medicos,
        datos_medicacion: state.datos_medicacion,
        signos: state.signos,
        selectedUnits: state.selectedUnits,
        documentos_adjuntos: {},
        otros_pregunta_formato: state.otros_pregunta_formato,
        alergias: state.alergias,
        locale: getCurrentLocale(), // Añade el idioma actual
      };

      const processDocumentosAdjuntos = async (documentos) => {
        const result = {};
        for (const key in documentos) {
          if (Array.isArray(documentos[key])) {
            result[key] = await Promise.all(
              documentos[key].map((file) => convertToBase64(file))
            );
          } else if (typeof documentos[key] === "object") {
            result[key] = await processDocumentosAdjuntos(documentos[key]);
          }
        }
        return result;
      };

      data.documentos_adjuntos = await processDocumentosAdjuntos(
        state.documentos_adjuntos
      );

      try {
        await axios.post("/api/visitantesfamiliares", data);
        commit("RESET_STATE");
      } catch (error) {
        // Manejo de error
      }
    },
  },
  modules: {
    dialcode,
  },
});
